import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useError } from '../../contexts/ErrorContext';
import './DataAnalysisModule.css';

function CorrelationTable({ analysisResults, moduleData, onUpdateModuleData }) {
  if (!analysisResults?.correlationMatrix) return null;

  // Get ignored correlations from moduleData or initialize empty array
  const ignoredCorrelations = moduleData?.data?.ignored_correlations || [];

  // Collect all unique pairs
  let pairs = [];
  const matrix = analysisResults.correlationMatrix;
  for (const colA of Object.keys(matrix)) {
    for (const colB of Object.keys(matrix[colA])) {
      // Only include each pair once (colA < colB)
      if (colA < colB) {
        const corrVal = matrix[colA][colB];
        // Create a unique key for this correlation pair
        const correlationKey = `${colA}:${colB}`;
        // Only add if not in ignored correlations
        if (!ignoredCorrelations.includes(correlationKey)) {
          pairs.push({ colA, colB, value: corrVal });
        }
      }
    }
  }

  // Filter out correlations below ±0.3
  pairs = pairs.filter(pair => Math.abs(pair.value) >= 0.3);

  // Sort pairs by absolute correlation (descending)
  pairs.sort((a, b) => Math.abs(b.value) - Math.abs(a.value));

  function getSignificance(value) {
    const absVal = Math.abs(value);
    if (absVal >= 0.7) return 'High';
    else if (absVal >= 0.3) return 'Moderate';
    else return 'Low';
  }

  async function handleIgnoreCorrelation(colA, colB) {
    // Create unique key for this correlation (always in same order)
    const correlationKey = colA < colB ? `${colA}:${colB}` : `${colB}:${colA}`;
    
    // Update moduleData with new ignored correlation
    const updatedModuleData = {
      ...moduleData,
      data: {
        ...moduleData.data,
        ignored_correlations: [...(moduleData.data.ignored_correlations || []), correlationKey]
      }
    };

    // Call parent handler to update the data
    onUpdateModuleData(updatedModuleData);
  }

  if (pairs.length === 0) {
    return (
      <div className="correlation-table-container">
        <h3>Correlation Table</h3>
        <p>No correlations above ±0.3</p>
      </div>
    );
  }

  return (
    <div className="correlation-table-container">
      <h3>Correlation Table</h3>
      <table className="correlation-table">
        <thead>
          <tr>
            <th>Column A</th>
            <th>Column B</th>
            <th>Correlation</th>
            <th>Significance</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {pairs.map((pair, index) => (
            <tr key={index}>
              <td>{pair.colA}</td>
              <td>{pair.colB}</td>
              <td>{pair.value.toFixed(2)}</td>
              <td>{getSignificance(pair.value)}</td>
              <td>
                <button
                  onClick={() => handleIgnoreCorrelation(pair.colA, pair.colB)}
                  className="text-gray-500 hover:text-red-500 transition-colors"
                  title="Ignore this correlation"
                >
                  <svg 
                    className="w-5 h-5" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                    />
                  </svg>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function DataAnalysisModule() {
  const { setError } = useError();
  const [moduleData, setModuleData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [generators, setGenerators] = useState([]);
  const [analysisResults, setAnalysisResults] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [newGeneratorName, setNewGeneratorName] = useState('');
  const [newGeneratorRegex, setNewGeneratorRegex] = useState('');
  const [newGeneratorValueExtractor, setNewGeneratorValueExtractor] = useState('');

  const [selectedGenerator, setSelectedGenerator] = useState(null);
  const [timeRange, setTimeRange] = useState('30'); // days

  const [editingGenerator, setEditingGenerator] = useState(null);
  const [editState, setEditState] = useState({
    name: '',
    regex: '',
    valueExtractor: '',
    ignoreCorrelations: false
  });

  const networkRef = useRef();

  useEffect(() => {
    fetchModuleData();
  }, []);

  async function fetchModuleData() {
    setLoading(true);
    try {
      const res = await axios.get('/api/objects', { withCredentials: true });
      const objects = res.data.objects;
      const found = objects.find(obj => obj.type === 'ModuleData' && obj.name === 'DataAnalysisModule');
      if (found) {
        setModuleData(found);
        setGenerators(found.data.dataGenerators || []);
        setAnalysisResults(found.data.analysisResults || null);
      } else {
        setModuleData(null);
      }
    } catch (err) {
      console.error('Error fetching module data:', err);
    } finally {
      setLoading(false);
    }
  }

  async function installModule() {
    try {
      setLoading(true);
      await axios.post('/api/modules/dataAnalysis/install', {}, { withCredentials: true });
      await fetchModuleData();
    } catch (err) {
      console.error('Error installing Data Analysis module:', err);
    } finally {
      setLoading(false);
    }
  }

  async function uninstallModule(keepData) {
    try {
      setLoading(true);
      await axios.post(
        '/api/modules/dataAnalysis/uninstall',
        { keepData },
        { withCredentials: true }
      );
      setModuleData(null);
      setGenerators([]);
      setAnalysisResults(null);
    } catch (err) {
      console.error('Error uninstalling Data Analysis module:', err);
    } finally {
      setLoading(false);
    }
  }

  async function runAnalysis() {
    try {
      setLoading(true);
      const days = parseInt(timeRange, 10);
      if (isNaN(days) || days < 1) {
        setError('Please enter a valid number of days bigger than 1');
        return;
      }
      
      const endDate = new Date().toISOString();
      const startDate = new Date(Date.now() - days * 24 * 60 * 60 * 1000).toISOString();
      
      const res = await axios.post('/api/modules/dataAnalysis/runAnalysis', 
        { startDate, endDate }, 
        { withCredentials: true }
      );
      if (res.data.analysisResults) {
        setAnalysisResults(res.data.analysisResults);
      }
    } catch (err) {
      console.error('Error running data analysis:', err);
      setError('Failed to run analysis: ' + (err.response?.data?.error || err.message));
    } finally {
      setLoading(false);
    }
  }

  async function addDataGenerator() {
    if (!moduleData) return;

    const valueExtractor = newGeneratorValueExtractor.trim() || '#exists';

    const newGen = {
      id: Math.random().toString(36).substr(2, 9),
      name: newGeneratorName.trim(),
      regex: newGeneratorRegex.trim(),
      valueExtractor
    };

    try {
      setLoading(true);
      const updatedGenerators = [...generators, newGen];

      const updatedModuleData = {
        ...moduleData,
        data: {
          ...moduleData.data,
          dataGenerators: updatedGenerators
        }
      };

      await axios.put(`/api/objects/${moduleData.id}`, updatedModuleData, { withCredentials: true });
      setGenerators(updatedGenerators);

      setNewGeneratorName('');
      setNewGeneratorRegex('');
      setNewGeneratorValueExtractor('');
    } catch (err) {
      console.error('Error adding data generator:', err);
    } finally {
      setLoading(false);
    }
  }

  async function updateGenerator(genId) {
    if (!moduleData) return;

    const updatedGenerators = generators.map(gen => {
      if (gen.id === genId) {
        return {
          ...gen,
          name: editState.name.trim(),
          regex: editState.regex.trim(),
          valueExtractor: editState.valueExtractor.trim() || '#exists',
          ignoreCorrelations: editState.ignoreCorrelations
        };
      }
      return gen;
    });

    try {
      setLoading(true);
      const updatedModuleData = {
        ...moduleData,
        data: {
          ...moduleData.data,
          dataGenerators: updatedGenerators
        }
      };

      await axios.put(`/api/objects/${moduleData.id}`, updatedModuleData, { withCredentials: true });
      setModuleData(updatedModuleData);
      setGenerators(updatedGenerators);
      setEditingGenerator(null);
      setEditState({ name: '', regex: '', valueExtractor: '', ignoreCorrelations: false });
    } catch (err) {
      console.error('Error updating generator:', err);
      setError('Failed to update generator: ' + (err.response?.data?.error || err.message));
    } finally {
      setLoading(false);
    }
  }

  function startEditing(gen) {
    setEditingGenerator(gen.id);
    setEditState({
      name: gen.name,
      regex: gen.regex,
      valueExtractor: gen.valueExtractor || '',
      ignoreCorrelations: gen.ignoreCorrelations || false
    });
  }

  function renderGeneratorsList() {
    if (generators.length === 0) {
      return <div>No data generators added yet.</div>;
    }
    return (
      <ul>
        {generators.map((gen, index) => (
          <li key={index}>
            <strong>{gen.name}</strong> | Regex: {gen.regex} | Value Extractor: {gen.valueExtractor}
          </li>
        ))}
      </ul>
    );
  }

  const renderDataVisualization = () => {
    if (!analysisResults?.dataEntries || analysisResults.dataEntries.length === 0) {
      return null;
    }

    const filteredEntries = analysisResults.dataEntries
      .filter(entry => entry.generatorName === selectedGenerator)
      .map(entry => ({
        timestamp: new Date(entry.timestamp),
        value: entry.value === '#exists' ? 1 : Number(entry.value) || 0
      }))
      .sort((a, b) => a.timestamp - b.timestamp);

    return (
      <div className="bg-white p-4 rounded-lg shadow-md">
        <LineChart width={600} height={300} data={filteredEntries}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="timestamp" 
            tickFormatter={(date) => new Date(date).toLocaleDateString()}
          />
          <YAxis />
          <Tooltip 
            labelFormatter={(date) => new Date(date).toLocaleString()}
          />
          <Legend />
          <Line 
            type="monotone" 
            dataKey="value" 
            stroke="#8884d8" 
            name={selectedGenerator} 
          />
        </LineChart>
      </div>
    );
  };

  function renderMultiLineChart() {
    if (!analysisResults?.dateArray || analysisResults.dateArray.length === 0) {
      return null;
    }
    
    // We'll reformat dateArray for Recharts directly
    // e.g. each object: { date: '2023-10-02', Gaming: 3, Mood: 5, ... }
    const data = analysisResults.dateArray.map(item => ({
      ...item,
      // Convert date string to something more readable or keep as is
      date: item.date
    }));
    
    // Each generatorName should be a separate <Line />
    return (
      <LineChart width={700} height={350} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="date"
          tickFormatter={dateStr => new Date(dateStr).toLocaleDateString()}
          angle={-15}
          tickMargin={10}
        />
        <YAxis />
        <Tooltip />
        <Legend />
        {analysisResults.generators?.map((genName) => (
          <Line
            key={genName}
            type="monotone"
            dataKey={genName}
            name={genName}
            stroke={'#' + Math.floor(Math.random() * 16777215).toString(16)} // random color
            strokeWidth={2}
            dot={false}
          />
        ))}
      </LineChart>
    );
  }

  function renderAnalysisDetails() {
    if (!analysisResults) return null;

    return (
      <div className="mt-6 space-y-6">
        {generators.map(gen => (
          <div key={gen.id} className="bg-white p-4 rounded-lg shadow">
            <h4 className="text-lg font-semibold mb-2">{gen.name}</h4>
            <div className="text-sm text-gray-600 mb-2">
              Regex: <code className="bg-gray-100 px-2 py-1 rounded">{gen.regex}</code>
            </div>
            
            {/* Matches scrollbox */}
            {analysisResults?.rawMatches?.filter(match => match.generator === gen.name).length > 0 ? (
              <div className="mt-4">
                <h5 className="text-sm font-medium text-gray-700 mb-2">Matches Found:</h5>
                <div className="matches-scrollbox">
                  {analysisResults.rawMatches
                    .filter(match => match.generator === gen.name)
                    .map((match, idx) => (
                      <div key={idx} className="match-item">
                        <span className="text-gray-500">
                          {new Date(match.timestamp).toLocaleDateString()}
                        </span>
                        <span className="mx-2">-</span>
                        <span className="text-gray-700">{match.eventSummary}</span>
                        {match.value && (
                          <span className="float-right text-blue-600">
                            Value: {match.value}
                          </span>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div className="text-gray-500 italic mt-2">No matches found</div>
            )}
          </div>
        ))}
      </div>
    );
  }

  const renderGenerator = (gen) => (
    <div
      className={`p-4 border rounded-lg transition-colors
        ${selectedGenerator === gen.name ? 'border-indigo-500 bg-indigo-50' : 'border-gray-200 hover:bg-gray-50'}`}
    >
      {editingGenerator === gen.id ? (
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              value={editState.name}
              onChange={(e) => setEditState(prev => ({ ...prev, name: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Regex Pattern</label>
            <input
              type="text"
              value={editState.regex}
              onChange={(e) => setEditState(prev => ({ ...prev, regex: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Value Extractor</label>
            <input
              type="text"
              value={editState.valueExtractor}
              onChange={(e) => setEditState(prev => ({ ...prev, valueExtractor: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="mr-2"
                checked={editState.ignoreCorrelations}
                onChange={(e) => setEditState({ ...editState, ignoreCorrelations: e.target.checked })}
              />
              Ignore internal data correlations
            </label>
          </div>
          <div className="flex justify-end gap-2">
            <button
              onClick={() => {
                setEditingGenerator(null);
                setEditState({ name: '', regex: '', valueExtractor: '', ignoreCorrelations: false });
              }}
              className="px-3 py-1 text-sm bg-gray-200 rounded hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={() => updateGenerator(gen.id)}
              className="px-3 py-1 text-sm bg-indigo-500 text-white rounded hover:bg-indigo-600"
            >
              Save
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-start">
            <div onClick={() => setSelectedGenerator(gen.name)} className="cursor-pointer flex-grow">
              <h4 className="font-medium text-lg">{gen.name}</h4>
              <div className="text-sm text-gray-600 mt-2">
                <p>Pattern: <code className="bg-gray-100 px-2 py-1 rounded">{gen.regex}</code></p>
                <p>Value Extractor: <code className="bg-gray-100 px-2 py-1 rounded">{gen.valueExtractor || '#exists'}</code></p>
              </div>
            </div>
            <button
              onClick={() => startEditing(gen)}
              className="ml-4 p-1 text-gray-500 hover:text-gray-700"
            >
              <span className="sr-only">Edit</span>
              <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
              </svg>
            </button>
          </div>
          {/* Display matched events if available */}
          {analysisResults?.dataEntries?.filter(entry => entry.generatorName === gen.name).length > 0 && (
            <div className="mt-4 border-t pt-4">
              <h5 className="text-sm font-medium text-gray-700 mb-2">Matched Events:</h5>
              <div className="space-y-2 max-h-40 overflow-y-auto">
                {analysisResults.dataEntries
                  .filter(entry => entry.generatorName === gen.name)
                  .map((entry, idx) => (
                    <div key={idx} className="text-sm text-gray-600 bg-gray-50 p-2 rounded">
                      <span className="font-medium">{new Date(entry.date).toLocaleDateString()}</span>
                      {entry.eventSummary && <span className="mx-2">-</span>}
                      {entry.eventSummary}
                      <span className="float-right">Value: {entry.rawValue}</span>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );

  async function handleUninstall(keepData = false) {
    try {
      setLoading(true);
      await axios.post(
        '/api/modules/dataAnalysis/uninstall',
        { keepData },
        { withCredentials: true }
      );
      setModuleData(null);
      setShowModal(false);
    } catch (error) {
      console.error('Error uninstalling Data Analysis module:', error);
    } finally {
      setLoading(false);
    }
  }

  async function handleUpdateModuleData(updatedData) {
    try {
      setLoading(true);
      await axios.put(`/api/objects/${moduleData.id}`, updatedData, { withCredentials: true });
      setModuleData(updatedData);
    } catch (err) {
      console.error('Error updating module data:', err);
      setError('Failed to update module data: ' + (err.response?.data?.error || err.message));
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <div>Loading Data Analysis Module...</div>;
  }

  if (!moduleData) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Data Analysis Module</h2>
        <p className="mb-6 text-gray-600">
          Create custom data trackers from your calendar events and discover patterns in your daily life.
        </p>
        <button
          onClick={installModule}
          className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white px-6 py-3 rounded-lg 
                   hover:from-blue-600 hover:to-indigo-600 transition-all duration-200 shadow-md"
        >
          Install Data Analysis Module
        </button>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-7xl mx-auto">
      {!moduleData ? (
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Data Analysis Module</h2>
          <p className="mb-6 text-gray-600">
            Create custom data trackers from your calendar events and discover patterns in your daily life.
          </p>
          <button
            onClick={installModule}
            className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white px-6 py-3 rounded-lg 
                     hover:from-blue-600 hover:to-indigo-600 transition-all duration-200 shadow-md"
          >
            Install Data Analysis Module
          </button>
        </div>
      ) : (
        <div className="space-y-6">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold text-gray-800">Data Analysis Module</h2>
            <button
              onClick={() => setShowModal(true)}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            >
              Uninstall
            </button>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6">
            <h3 className="text-xl font-semibold mb-4">Data Generators</h3>
            
            <div className="grid grid-cols-2 gap-4 mb-6 p-4 bg-gray-50 rounded-lg">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Name</label>
                  <input
                    type="text"
                    value={newGeneratorName}
                    onChange={(e) => setNewGeneratorName(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="e.g., Gaming Time"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Regex Pattern</label>
                  <input
                    type="text"
                    value={newGeneratorRegex}
                    onChange={(e) => setNewGeneratorRegex(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="e.g., ^Played"
                  />
                </div>
              </div>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Value Extractor (leave empty to record #exists)</label>
                  <input
                    type="text"
                    value={newGeneratorValueExtractor}
                    onChange={(e) => setNewGeneratorValueExtractor(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="e.g., $1 or #time"
                  />
                </div>
              </div>
              <button
                onClick={addDataGenerator}
                className="col-span-2 bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600"
              >
                Add Generator
              </button>
            </div>

            <div className="space-y-4">
              {generators.map((gen) => (
                <div key={gen.id || Math.random()}>
                  {renderGenerator(gen)}
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold">Analysis</h3>
              <div className="flex gap-4">
                <div className="flex items-center gap-2">
                  <label className="text-sm text-gray-600">Last</label>
                  <input
                    type="number"
                    min="1"
                    value={timeRange}
                    onChange={(e) => setTimeRange(e.target.value)}
                    className="w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                  <span className="text-sm text-gray-600">days</span>
                </div>
                <button
                  onClick={runAnalysis}
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                >
                  Run Analysis
                </button>
              </div>
            </div>

            {/* Multi-line chart for all generators, by date */}
            {renderMultiLineChart()}

            {renderAnalysisDetails()}
          </div>

          {/* Render the correlation table instead of the D3 network */}
          <CorrelationTable 
            analysisResults={analysisResults} 
            moduleData={moduleData}
            onUpdateModuleData={handleUpdateModuleData}
          />
        </div>
      )}

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-md">
            <h3 className="text-xl font-bold mb-4">Uninstall Module</h3>
            <p className="mb-6">Do you want to keep your data when uninstalling?</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => handleUninstall(true)}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                Keep Data
              </button>
              <button
                onClick={() => handleUninstall(false)}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Remove Everything
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DataAnalysisModule; 