import React, { useEffect, useState } from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

const ScrollArrow = ({ direction, onClick }) => (
    <button
        onClick={onClick}
        className={`fixed right-4 z-50 bg-white rounded-full p-3 shadow-lg hover:bg-gray-100 transition-all ${
            direction === 'up' ? 'top-4' : 'bottom-4'
        }`}
    >
        {direction === 'up' ? <FaArrowUp /> : <FaArrowDown />}
    </button>
);

const ScrollToArrows = () => {
    const [showUpArrow, setShowUpArrow] = useState(false);
    const [showDownArrow, setShowDownArrow] = useState(false);
    const [canScroll, setCanScroll] = useState(false);

    const checkScrollability = () => {
        const element = document.documentElement;
        const scrollTop = window.scrollY;
        const scrollHeight = element.scrollHeight;
        const clientHeight = element.clientHeight;
        
        const newCanScroll = scrollHeight > clientHeight;
        const isAtTop = scrollTop <= 0;
        const isAtBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1;
        
        setCanScroll(newCanScroll);
        setShowUpArrow(newCanScroll && !isAtTop);
        setShowDownArrow(newCanScroll && !isAtBottom);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const scrollToBottom = () => {
        window.scrollTo({ 
            top: document.documentElement.scrollHeight, 
            behavior: 'smooth' 
        });
    };

    useEffect(() => {
        // Create ResizeObserver to detect content height changes
        const resizeObserver = new ResizeObserver(checkScrollability);
        resizeObserver.observe(document.body);

        // Add scroll listener
        window.addEventListener('scroll', checkScrollability);
        
        // Initial check after a short delay to allow content to render
        setTimeout(checkScrollability, 100);

        return () => {
            resizeObserver.disconnect();
            window.removeEventListener('scroll', checkScrollability);
        };
    }, []);

    if (!canScroll) return null;

    return (
        <>
            {showUpArrow && <ScrollArrow direction="up" onClick={scrollToTop} />}
            {showDownArrow && <ScrollArrow direction="down" onClick={scrollToBottom} />}
        </>
    );
};

export default ScrollToArrows; 