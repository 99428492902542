import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function EventsPage() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch only events that have annotations or labels
    axios.get('/api/events', { withCredentials: true })
      .then((response) => {
        setEvents(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching events:', error);
        setLoading(false);
      });
  }, []);

  if (loading) return <div className="p-4">Loading events...</div>;

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Annotated Events</h1>
      </div>

      <div className="space-y-4">
        {events.length === 0 ? (
          <p className="text-gray-500">No annotated events found</p>
        ) : (
          events.map((event) => (
            <Link
              key={event.id}
              to={`/events/${event.id}`}
              className="block p-4 border rounded-lg hover:bg-gray-50"
            >
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="font-semibold">{event.eventData.summary || event.name || 'No Title'}</h3>
                  <p className="text-sm text-gray-600">
                    {new Date(event.eventData.start.dateTime || event.eventData.start.date).toLocaleString()}
                  </p>
                  {event.labels && event.labels.length > 0 && (
                    <div className="flex gap-2 mt-2">
                      {event.labels.map(label => (
                        <span key={label} className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs">
                          {label}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
                <div className="text-sm text-gray-500">
                  {event.annotations && Object.keys(event.annotations).length} annotations
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
    </div>
  );
}

export default EventsPage; 