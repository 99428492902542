// client/src/components/NotePage.js

import React, { useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { getLocalDate } from '../utils/webUtils';
import { useError } from '../contexts/ErrorContext';

const NotePage = ({ note, setNote, noteId, saveEdits }) => {
  const navigate = useNavigate();
  const { showError } = useError();

  useEffect(() => {
    if (noteId === 'new') {
      axios
        .post('/api/notes', 
          { date: getLocalDate() }, 
          { withCredentials: true }
        )
        .then((response) => {
          const newNote = response.data.note;
          navigate(`/notes/${newNote.id}`, { replace: true });
        })
        .catch((error) => {
          console.error('Error creating new note:', error);
          showError('Error creating new note:', error);
        });
    }
  }, [noteId, navigate]);

  const handleChange = (field, value) => {
    setNote({
      ...note,
      [field]: value,
    });
  };

  const saveNote = async () => {
    await saveEdits();
    alert('Note saved successfully!');
  };

  if (!note) {
    return <div>Loading...</div>;
  }

  return (
    <div className="note-page">
      <h1>
        <input
          type="text"
          value={note.name}
          onChange={(e) => handleChange('name', e.target.value)}
          placeholder="Note Title"
          className="border p-1 w-full mb-4"
        />
      </h1>
      <textarea
        value={note.content}
        onChange={(e) => handleChange('content', e.target.value)}
        placeholder="Write your note here..."
        className="border p-2 w-full mb-4"
      />
      <ReactMarkdown>{note.content}</ReactMarkdown>
      <div className="mt-4">
        <label className="block mb-2 font-bold">Labels (comma-separated):</label>
        <input
          type="text"
          value={note.labels.join(', ')}
          onChange={(e) => handleChange('labels', e.target.value.split(',').map(label => label.trim()))}
          className="w-full p-2 border rounded"
        />
      </div>
      <button onClick={saveNote} className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
        Save Note
      </button>
    </div>
  );
};

export default NotePage;
