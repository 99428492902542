import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC3gJmZWBqrIrFvYRMHaBeDQXn5MQyvgXw",
  authDomain: "personalcanvas-977d7.firebaseapp.com",
  projectId: "personalcanvas-977d7",
  storageBucket: "personalcanvas-977d7.appspot.com",
  messagingSenderId: "58853985091",
  appId: "1:58853985091:web:27a79abc791e33f146bdf6",
  measurementId: "G-ZBTTK3Y5EW"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const messaging = getMessaging(app);
const storage = getStorage();

export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: 'BAZIolcRqjPlZq31CIIpNUGHAx1MbKUMHLcHMIHzpzIuqBqusv7-33VvVSeiIbMiZFH4Y9Z6B3biZl6jwHDJ_AE'
      });
      return token;
    }
  } catch (error) {
    console.error('Notification permission error:', error);
  }
  return null;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const uploadFile = async (user, file) => {
  if (!user.id) throw new Error('User ID is required to upload a file');
  const storageRef = ref(storage, `users/${user.id}/uploads/${uuidv4()}-${file.name}`);
  const snapshot = await uploadBytesResumable(storageRef, file);
  const downloadURL = await getDownloadURL(snapshot.ref);
  return downloadURL;
};

export { auth, provider, messaging };