// client/src/pages/ModulesPage.js
import React, { useState, useEffect } from 'react';
import LifeAreasModule from '../components/Modules/LifeAreasModule';
import ClosePeopleModule from '../components/Modules/ClosePeopleModule';
import GratitudeJarModule from '../components/Modules/GratitudeJarModule';
import AutoDiaryModule from '../components/Modules/AutoDiaryModule';
import DataAnalysisModule from '../components/Modules/DataAnalysisModule';
import { useError } from '../contexts/ErrorContext';
import { usePageTitle } from '../hooks/usePageTitle';
import { useObjects } from '../contexts/ObjectsContext';

function ModulesPage() {
  const [availableModules] = useState([
    {
      name: 'LifeAreasModule',
      description: 'Track and improve different areas of your life with AI Watchers',
      component: LifeAreasModule
    },
    {
      name: 'ClosePeopleModule',
      description: 'Track and improve your relationships with people close to you',
      component: ClosePeopleModule
    },
    {
      name: 'GratitudeJarModule',
      description: 'Write a quick weekly gratitude note, saved in a jar',
      component: GratitudeJarModule
    },
    {
      name: 'AutoDiaryModule',
      description: 'Add your day\'s events to the calendar via AI',
      component: AutoDiaryModule
    },
    {
      name: 'DataAnalysisModule',
      description: 'Analyze your data',
      component: DataAnalysisModule
    }
  ]);
  const [activeModules, setActiveModules] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showError } = useError();
  const { objects, getObjects, isLoading: isLoadingObjects } = useObjects();

  usePageTitle('Modules');

  const loadActiveModules = async () => {
    try {
      const moduleData = objects.filter(obj => obj.type === 'ModuleData');

      // Find which modules are already initialized
      const activeModule = moduleData.map(md => {
        return availableModules.find(m => m.name === md.name);
      }).filter(Boolean);
      
      setActiveModules(activeModule);
    } catch (error) {
      console.error('Error loading active modules:', error);
      showError('Error loading active modules');
    }
    setLoading(false);
  };

  useEffect(() => {
    getObjects();
  }, []);

  useEffect(() => {
    loadActiveModules();
  }, [objects]);

  if (loading || isLoadingObjects) {
    return <div>Loading Modules...</div>;
  }

  return (
    <div className="p-4">
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Available Modules</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {availableModules.map(module => (
            <div key={module.name} className="border p-4 rounded shadow">
              <h3 className="font-bold">{module.name}</h3>
              <p className="text-gray-600 mb-4">{module.description}</p>
              {!activeModules.includes(module) && (
                <button
                  onClick={() => setActiveModules([...activeModules, module])}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Add
                </button>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="space-y-8">
        {activeModules.map(module => {
          const ModuleComponent = module.component;
          return (
            <div key={module.name} className="border rounded-lg p-4 shadow">
              <h2 className="text-xl font-bold mb-4">{module.name}</h2>
              <ModuleComponent />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ModulesPage;
