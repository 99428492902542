import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import axios from 'axios';
import './Calendar.css';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { useError } from '../contexts/ErrorContext';

export const getCalendarColors = (backgroundColor) => {
  let textColor = '#ffffff'; // default white text

  if (backgroundColor) {
    // Convert hex to RGB and calculate brightness
    const hex = backgroundColor.slice(1); // remove #
    const rgb = hex.match(hex.length === 6 ? /(\S{2})/g : /(\S{1})/g);
    if (rgb) {
      const r = parseInt(rgb[0], 16);
      const g = parseInt(rgb[1], 16);
      const b = parseInt(rgb[2], 16);
      // Calculate perceived brightness using the same formula
      const brightness = (r * 299 + g * 587 + b * 114) / 1000;
      if (brightness > 150) {
        textColor = '#000000'; // Use black text on light backgrounds
      }
    }
  }

  return {
    backgroundColor: backgroundColor || '#3788d8',
    borderColor: backgroundColor || '#3788d8',
    textColor: textColor
  };
};

const Calendar = () => {
  const navigate = useNavigate();
  const [eventSources, setEventSources] = useState([]);
  const { setError } = useError();

  // Event selection
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [fetchedEvents, setFetchedEvents] = useState({});

  // Add new state for blur toggle
  const [blurEvents, setBlurEvents] = useState(false);

  useEffect(() => {
    // Fetch full calendar list
    axios.get('/api/calendars/lists', { withCredentials: true })
      .then((response) => {
        // Create an event source for each calendar
        const sources = response.data.map(calendar => ({
          id: calendar.id,
          events: (info, successCallback, failureCallback) =>
            fetchEventsForCalendar(calendar.id, info, successCallback, failureCallback),
          ...getCalendarColors(calendar.backgroundColor)
        }));
        setEventSources(sources);
      })
      .catch((error) => {
        console.error('Error fetching calendar lists:', error);
        setError('Error fetching calendar lists');
      });
  }, []);

  const fetchEventsForCalendar = async (calendarId, info, successCallback, failureCallback) => {
    try {
      const response = await axios.get('/api/calendars/events', {
        params: {
          timeMin: info.start.toISOString(),
          timeMax: info.end.toISOString(),
          calendarId
        },
        withCredentials: true,
      });

      const events = response.data.map(event => ({
        id: event.id,
        title: event.summary,
        start: event.start.dateTime || event.start.date,
        end: event.end.dateTime || event.end.date,
        allDay: !event.start.dateTime,
        description: event.description,
        location: event.location
      }));

      successCallback(events);
    } catch (error) {
      console.error('Error fetching events:', error);
      failureCallback(error);
    }
  };

  const fetchEvent = async (eventId, calendarId) => {
    try {
      const response = await axios.get('/api/events/event', {
        params: {
          event_id: eventId,
          calendar_id: calendarId,
          check: true
        },
        withCredentials: true
      });
      setFetchedEvents(prev => ({
        ...prev,
        [eventId]: response.data.existing ? response.data.event : { event_id: eventId, calendar_id: calendarId }
      }));
      return response.data.existing ? response.data.event : { event_id: eventId, calendar_id: calendarId };
    } catch (error) {
      console.error('Error fetching event:', error);
      setFetchedEvents(prev => ({
        ...prev,
        [eventId]: { event_id: eventId, calendar_id: calendarId }
      }));
      return { event_id: eventId, calendar_id: calendarId };
    }
  };

  const handleEventClick = async (clickInfo) => {
    const eventId = clickInfo.event.id;
    const calendarId = clickInfo.event.source.id;
    const event = {
      event_id: eventId,
      calendar_id: calendarId,
      eventData: {
        summary: clickInfo.event.title,
        start: clickInfo.event.start,
        end: clickInfo.event.end,
        allDay: clickInfo.event.allDay,
        description: clickInfo.event.description,
        location: clickInfo.event.location
      },
      ...fetchedEvents[eventId],
    }
    setSelectedEvent(event);
    setModalOpen(true);
  };

  // Modified eventDidMount to handle annotations
  const eventDidMount = (info) => {
    const eventId = info.event.id;
    const calendarId = info.event.source.id;

    info.el.classList.add('unchecked-event');

    if (fetchedEvents[eventId]) {
      info.el.classList.add('annotated-event');
      info.el.classList.remove('unchecked-event');
    } else {
      fetchEvent(eventId, calendarId).then(event => {
        if (event.labels && event.labels.length > 0 || event.annotations && Object.keys(event.annotations).length > 0) {
          info.el.classList.add('annotated-event');
        }
        info.el.classList.remove('unchecked-event');
      });
    }
  };

  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingTop: '100px',
      zIndex: 1000
    },
    content: {
      position: 'relative',
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      width: '400px',
      padding: '20px',
      borderRadius: '8px',
      backgroundColor: 'white',
      boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
    }
  };

  return (
    <div className="space-y-4">
      
      <div className={`h-[600px] ${blurEvents ? 'blur-events' : ''}`}>
        <style>
          {`
            .unchecked-event {
              opacity: 0.6;
              transition: opacity 0.3s ease;
            }
            .annotated-event {
              border: 2px solid #3b82f6 !important;
              border-radius: 4px;
            }
          `}
        </style>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridWeek,dayGridMonth,timeGridDay'
          }}
          eventSources={eventSources}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          }}
          height="100%"
          eventDidMount={eventDidMount}
          eventClick={handleEventClick}
          firstDay={1}
          nowIndicator={true}
        />
      </div>

      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          id="blur-events"
          checked={blurEvents}
          onChange={(e) => setBlurEvents(e.target.checked)}
          className="h-4 w-4 text-blue-600"
        />
        <label htmlFor="blur-events" className="text-sm text-gray-700">
          Blur Event Titles
        </label>
      </div>
      
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={modalStyles}
        ariaHideApp={false}
      >
        {selectedEvent && (
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="text-xl font-semibold">{selectedEvent.eventData.summary}</h3>
              <button
                onClick={() => setModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>

            <div className="text-sm text-gray-600">
              {selectedEvent.allDay ? (
                <div>All day</div>
              ) : (
                <div>
                  {new Date(selectedEvent.eventData.start).toLocaleTimeString()} -
                  {new Date(selectedEvent.eventData.end).toLocaleTimeString()}
                </div>
              )}
            </div>

            {selectedEvent.location && (
              <div className="text-sm">
                <span className="font-medium">Location: </span>
                {selectedEvent.eventData.location}
              </div>
            )}

            {selectedEvent.description && (
              <div className="text-sm">
                <span className="font-medium">Description: </span>
                {selectedEvent.eventData.description}
              </div>
            )}

            {selectedEvent.labels && selectedEvent.labels.length > 0 && (
              <div className="space-y-2">
                <h4 className="font-medium">Labels</h4>
                {selectedEvent.labels.map(label => (
                  <div key={label} className="flex justify-between text-sm">{label}</div>
                ))}
              </div>
            )}

            {selectedEvent.annotations && Object.keys(selectedEvent.annotations).length > 0 && (
              <div className="space-y-2">
                <h4 className="font-medium">Annotations</h4>
                {Object.entries(selectedEvent.annotations).map(([key, value]) => (
                  <div key={key} className="flex justify-between text-sm">
                    <span className="text-gray-600">{key}:</span>
                    <span>{value}</span>
                  </div>
                ))}
              </div>
            )}

            <div className="flex justify-end space-x-2 pt-4">
              <button
                onClick={async () => {
                  try {
                    // Create new event first
                    const response = await axios.post('/api/events', {
                      event_id: selectedEvent.event_id,
                      calendar_id: selectedEvent.calendar_id,
                    }, { withCredentials: true });

                    // Navigate to the event page with the new DB id
                    if (response.data.event.id) {
                      navigate(`/events/${response.data.event.id}`);
                    }
                  } catch (error) {
                    console.error('Error creating event:', error);
                  }
                }}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Edit Details
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Calendar;
