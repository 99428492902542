import React from 'react';
import { Link } from 'react-router-dom';

function FileItem({ file }) {
    const getFileIcon = (path) => {
        if (path.endsWith('.js')) return '📄';
        if (path.endsWith('.css')) return '🎨';
        if (path.endsWith('.html')) return '🌐';
        if (path.endsWith('.json')) return '📋';
        if (/\d{4}-\d{2}-\d{2}$/.test(path)) return '📅';
        return '📄';
    };

    return (
        <div className="flex items-start gap-2">
            <span className="text-xl">{getFileIcon(file.path)}</span>
            <div>
                <div className="text-sm text-gray-600">{file.path}</div>
                <Link to={`/files/${file.id}`} className="text-blue-500 hover:underline">
                    Open File
                </Link>
            </div>
        </div>
    );
}

export default FileItem; 