import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useError } from '../contexts/ErrorContext';

function EventPage() {
    const { database_id } = useParams();
    const [event, setEvent] = useState(null);
    const [annotationText, setAnnotationText] = useState('');
    const { setError } = useError();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                // Get event from our database
                const eventResponse = await axios.get(`/api/events/${database_id}`, { withCredentials: true });
                const event = eventResponse.data;
                setEvent(event);
            } catch (err) {
                console.error('Error loading event:', err);
            }
        };

        fetchEventDetails();
    }, [database_id]);

    useEffect(() => {
        // Set annotation text
        if (event && event.annotations) {
            setAnnotationText(JSON.stringify(event.annotations, null, 2));
        }
    }, [event]);

    const handleSaveChanges = () => {
        // Convert annotation text back to object
        let annotations = {};
        try {
            annotations = JSON.parse(annotationText);
        } catch (error) {
            setError('Error parsing annotation');
            console.error('Error parsing annotation:', error);
            return;
        }

        axios.post('/api/events', {
            id: event.id,
            event_id: event.event_id,
            calendar_id: event.calendar_id,
            labels: event.labels,
            annotations: annotations,
        }, { withCredentials: true })
            .then(() => {
                alert('Event updated!');
            })
            .catch(err => {
                console.error('Error updating event:', err);
                alert('Failed to update event. Please try again.');
            });
    };

    const handleDelete = async () => {
        if (!window.confirm('Are you sure you want to delete this event?')) {
            return;
        }

        try {
            await axios.delete(`/api/events/${database_id}`, { withCredentials: true });
            navigate('/events');
        } catch (err) {
            console.error('Error deleting event:', err);
            setError('Failed to delete event. Please try again.');
        }
    };

    if (!event) return <div className="p-4">Loading event...</div>;

    return (
        <div className="max-w-3xl mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">
                {event.eventData.summary || 'Untitled Event'}
            </h1>

            {event.eventData.start && (
                <div className="mb-6 text-gray-600">
                    {new Intl.DateTimeFormat('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        timeZoneName: 'short'
                    }).format(new Date(event.eventData.start.dateTime || event.eventData.start.date))}
                    {' - '}
                    {new Intl.DateTimeFormat('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        timeZoneName: 'short'
                    }).format(new Date(event.eventData.end.dateTime || event.eventData.end.date))}
                </div>
            )}

            <div className="space-y-6">
                <div>
                    <h2 className="text-xl font-semibold mb-2">Labels</h2>
                    <input
                        type="text"
                        value={event.labels.join(', ')}
                        onChange={(e) => {
                            const labels = e.target.value.split(',').map(label => label.trim()).filter(Boolean);
                            setEvent(prev => ({ ...prev, labels }));
                        }}
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Add comma-separated labels..."
                    />
                </div>

                <div>
                    <h2 className="text-xl font-semibold mb-2">Annotations</h2>
                    <p className="text-sm text-gray-600 mb-2">
                        Add annotations in "key: value" format, one per line
                    </p>
                    <textarea
                        value={annotationText}
                        onChange={(e) => setAnnotationText(e.target.value)}
                        className="w-full h-48 p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 font-mono"
                        placeholder="location: Conference Room A&#10;topic: Q4 Planning&#10;attendees: 5"
                    />
                </div>

                <div className="flex justify-end space-x-4">
                    <button
                        onClick={handleDelete}
                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                        Delete Event
                    </button>
                    <button
                        onClick={handleSaveChanges}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EventPage; 