import { useState, useEffect, createContext, useContext } from 'react';
import axios from 'axios';

const ObjectsContext = createContext(null);

export function ObjectsProvider({ children }) {
  const [objects, setObjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [lastKnownHash, setLastKnownHash] = useState(null);

  // Fetch the server's current hash for all objects
  const checkObjectsHash = async () => {
    try {
      const { data } = await axios.get('/api/objects/hash', { withCredentials: true });
      return data.hash;
    } catch (error) {
      console.error('Error fetching objects hash:', error);
      return null;
    }
  };

  // Fetch the actual objects from the server
  const fetchObjects = async () => {
    if (isFetching) return null;
    setIsFetching(true);

    try {
      const response = await axios.get('/api/objects', { withCredentials: true });
      const responseObjects = response.data.objects || [];

      // Sort the objects in a consistent way, if desired
      const objectsWithStartDate = responseObjects.filter(obj => obj.start);
      objectsWithStartDate.sort((a, b) => new Date(b.start.dateTime) - new Date(a.start.dateTime));
      const objectsWithoutStartDate = responseObjects.filter(obj => !obj.start);
      const sortedObjects = [...objectsWithStartDate, ...objectsWithoutStartDate];

      setObjects(sortedObjects);
      setIsLoading(false);
      return sortedObjects;
    } catch (error) {
      console.error('Error fetching objects:', error);
      return null;
    } finally {
      setIsFetching(false);
    }
  };

  // Main method to ensure we have up-to-date objects
  const getObjects = async () => {
    try {
      setIsLoading(true);

      // 1) Get new hash from server
      const serverHash = await checkObjectsHash();
      if (!serverHash) {
        // If there was an error or no hash was returned, we fallback to fetching:
        await fetchObjects();
        return;
      }

      // 2) Compare with lastKnownHash; if different, fetch
      if (serverHash !== lastKnownHash) {
        await fetchObjects();
        setLastKnownHash(serverHash);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error checking objects hash:', error);
    }
  };

  useEffect(() => {
    getObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteObject = (objectId) => {
    setObjects(prevObjects => {
      return prevObjects.filter(obj => obj.id !== objectId);
    });
  };

  const value = {
    objects,
    setObjects,
    deleteObject: handleDeleteObject,
    isLoading,
    getObjects,
    isFetching
  };

  return (
    <ObjectsContext.Provider value={value}>
      {children}
    </ObjectsContext.Provider>
  );
}

export function useObjects() {
  const context = useContext(ObjectsContext);
  if (!context) {
    throw new Error('useObjects must be used within an ObjectsProvider');
  }
  return context;
} 