import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLocalDate } from '../utils/webUtils';
import axios from 'axios';
import { useError } from '../contexts/ErrorContext';

function FilePage({ object, setObject, objectId, saveEdits }) {
    const navigate = useNavigate();
    const { showError } = useError();

    useEffect(() => {
        if (objectId === 'new') {
            axios
                .post('/api/files',
                    { date: getLocalDate() },
                    { withCredentials: true }
                )
                .then((response) => {
                    const newFile = response.data.object;
                    navigate(`/files/${newFile.id}`, { replace: true });
                })
                .catch((error) => {
                    console.error('Error creating new file:', error);
                    showError('Error creating new file:', error);
                });
        }
    }, [objectId, navigate]);

    const handleChange = (field, value) => {
        setObject({
            ...object,
            [field]: value,
        });
    };

    const saveObject = async () => {
        await saveEdits();
        alert('Object saved successfully!');
    };

    if (!object) {
        return <div>Loading...</div>;
    }

    return (
        <div className="file-page p-4">
            <div className="mb-6">
                <input
                    type="text"
                    value={object.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                    placeholder="File Name"
                    className="text-2xl font-bold w-full mb-2 p-2 border rounded"
                />
                <input
                    type="text"
                    value={object.path}
                    onChange={(e) => handleChange('path', e.target.value)}
                    placeholder="/apps/MyApp/file.js"
                    className="w-full p-2 border rounded text-gray-600"
                />
            </div>

            <div className="mb-4">
                <label className="block mb-2 font-bold">Content:</label>
                <textarea
                    value={object.content || ''}
                    onChange={(e) => handleChange('content', e.target.value)}
                    placeholder="File content..."
                    className="w-full p-2 border rounded font-mono"
                    rows={20}
                />
            </div>

            <div className="mb-4">
                <label className="block mb-2 font-bold">Labels:</label>
                <input
                    type="text"
                    value={object.labels ? object.labels.join(', ') : ''}
                    onChange={(e) => handleChange('labels', e.target.value.split(',').map(label => label.trim()))}
                    className="w-full p-2 border rounded"
                    placeholder="file, javascript, etc..."
                />
            </div>

            <button 
                onClick={saveObject} 
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
                Save File
            </button>
        </div>
    );
}

export default FilePage; 