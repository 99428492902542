// client/src/components/ObjectItem.js

import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useObjects } from '../contexts/ObjectsContext';
import NoteItem from './NoteItem';
import ConversationItem from './ConversationItem';
import JobItem from './JobItem';
import FileItem from './FileItem';
import CanvasItem from './CanvasItem';

function ObjectItem({ object }) {
  const { deleteObject } = useObjects();

  const onDeleteObject = async () => {
    if (!window.confirm('Are you sure you want to delete this object?')) {
      return;
    }

    try {
      await axios.delete(`/api/objects/${object.id}`, { withCredentials: true });
      deleteObject(object.id);
    } catch (error) {
      console.error('Error deleting object:', error);
    }
  }

  const getContentPreview = () => {
    if (!object.content) return '';
    return object.content.length > 50 
      ? object.content.substring(0, 50) + '...'
      : object.content;
  };

  const internalObject = () => {
    if (object.type === 'Note') {
      return <NoteItem note={object} />;
    }
    if (object.type === 'Conversation') {
      return <ConversationItem conversation={object} />;
    }
    if (object.type === 'Job') {
      return <JobItem job={object} />;
    }
    if (object.type === 'File') {
      return <FileItem file={object} />;
    }
    if (object.type === 'Canvas') {
      return <CanvasItem canvas={object} />;
    }
    return (
      <div>
        <Link to={`/objects/${object.id}`} className="text-blue-500 hover:underline">
          View Object
        </Link>
      </div>
    );
  };

  return (
    <div className="p-4 border rounded shadow mb-2">
      <h2 className="text-xl font-semibold">{object.name || 'Untitled'}</h2>
      <p>Type: {object.type}</p>
      {object.labels && object.labels.length > 0 && (
        <p>Labels: {object.labels.join(', ')}</p>
      )}
      {object.content && (
        <p className="text-gray-600 mt-2">{getContentPreview()}</p>
      )}

      {internalObject()}

      <button
        onClick={onDeleteObject}
        className="text-red-500 hover:underline"
      >
        Delete
      </button>
    </div>
  );
}

export default ObjectItem;
