// client/src/App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import ModulesPage from './pages/ModulesPage.js';
import Dashboard from './pages/Dashboard.js';
import ObjectsPage from './pages/ObjectsPage.js';
import ConversationPage from './pages/ConversationPage.js';
import NotePage from './pages/NotePage.js';
import ObjectPage from './pages/ObjectPage.js';
import ObjectLoader from './components/ObjectLoader';
import { UserContextProvider } from './contexts/UserContext';
import JobPage from './pages/JobPage.js';
import { register } from './serviceWorkerRegistration.js';
import { NotificationProvider } from './contexts/NotificationContext';
import Notifications from './components/Notifications';
import { ErrorProvider } from './contexts/ErrorContext';
import ErrorDisplay from './components/ErrorDisplay';
import AppPage from './pages/AppPage.js';
import FilePage from './pages/FilePage.js';
import { ObjectsProvider } from './contexts/ObjectsContext';
import ScrollToArrows from './components/ScrollToArrows';
import CanvasPage from './pages/CanvasPage.js';
import EventsPage from './pages/EventsPage';
import EventPage from './pages/EventPage';
import ObjectEditor from './components/ObjectEditor.js';

function App() {
  const [waitingWorker, setWaitingWorker] = useState(null);

  useEffect(() => {
    // Register service worker update handler
    if ('serviceWorker' in navigator) {
      const handleServiceWorkerUpdate = (registration) => {
        setWaitingWorker(registration.waiting);
      };

      register({
        onUpdate: handleServiceWorkerUpdate,
      });
    }
  }, []);

  const handleLogout = () => {
    window.location.href = '/auth/logout';
  };

  return (
    <UserContextProvider>
      <ErrorProvider>
        <NotificationProvider>
          <ObjectsProvider>
            <Router>
              <nav className="p-4 bg-gray-800 text-white flex justify-between items-center">
                <div className="flex gap-4">
                  <Link to="/dashboard">Dashboard</Link>
                  <Link to="/objects">Objects</Link>
                  <Link to="/modules">Modules</Link>
                  <Link to="/events">Events</Link>
                </div>
                <div>
                  <button
                    onClick={handleLogout}
                    className="bg-red-500 text-white px-4 py-2 rounded"
                  >
                    Logout
                  </button>
                </div>
              </nav>
              <Notifications />
              <main className="container mx-auto px-4 py-4">
                <ErrorDisplay />
                <Routes>
                  <Route path="/modules" element={<ModulesPage />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/objects" element={<ObjectsPage />} />
                  <Route path="/notes/:objectId" element={
                    <ObjectLoader component={(object, setObject, objectId, saveEdits, isSaving) =>
                      <ObjectEditor
                        object={object}
                        setObject={setObject}
                        component={() =>
                          <NotePage
                            note={object}
                            setNote={setObject}
                            noteId={objectId}
                            saveEdits={saveEdits}
                            isSaving={isSaving}
                          />}
                      />}
                    />}
                  />
                  <Route path="/conversations/:objectId" element={
                    <ObjectLoader component={(object, setObject, objectId, saveEdits, isSaving, loadObject) =>
                      <ObjectEditor
                        object={object}
                        setObject={setObject}
                        component={() =>
                          <ConversationPage
                            conversation={object}
                            setConversation={setObject}
                            conversationId={objectId}
                            saveEdits={saveEdits}
                            isSaving={isSaving}
                            loadObject={loadObject}
                          />}
                      />}
                    />}
                  />
                  <Route path="/jobs/:objectId" element={
                    <ObjectLoader component={(object, setObject, objectId, saveEdits, isSaving, loadObject) =>
                      <ObjectEditor
                        object={object}
                        setObject={setObject}
                        component={() =>
                          <JobPage
                            job={object}
                            setJob={setObject}
                            jobId={objectId}
                            saveEdits={saveEdits}
                            isSaving={isSaving}
                            loadObject={loadObject}
                          />}
                      />}
                    />}
                  />
                  <Route path="/objects/:objectId" element={
                    <ObjectLoader component={(object, setObject, objectId, saveEdits, isSaving) =>
                      <ObjectEditor
                        object={object}
                        setObject={setObject}
                        component={() =>
                          <ObjectPage
                            object={object}
                            setObject={setObject}
                            objectId={objectId}
                            saveEdits={saveEdits}
                            isSaving={isSaving}
                          />}
                      />}
                    />}
                  />
                  <Route path="/apps/:objectId" element={
                    <ObjectLoader component={(object, setObject, objectId, saveEdits, isSaving) =>
                      <ObjectEditor
                        object={object}
                        setObject={setObject}
                        component={() =>
                          <AppPage
                            app={object}
                            setApp={setObject}
                            appId={objectId}
                            saveEdits={saveEdits}
                            isSaving={isSaving}
                          />}
                      />}
                    />}
                  />
                  <Route path="/files/:objectId" element={
                    <ObjectLoader component={(object, setObject, objectId, saveEdits) =>
                      <ObjectEditor
                        object={object}
                        setObject={setObject}
                        component={() =>
                          <FilePage
                            object={object}
                            setObject={setObject}
                            objectId={objectId}
                            saveEdits={saveEdits}
                          />}
                      />}
                    />}
                  />
                  <Route path="/canvases/:objectId" element={
                    <ObjectLoader component={(object, setObject, objectId, saveEdits) =>
                      <ObjectEditor
                        object={object}
                        setObject={setObject}
                        component={() =>
                          <CanvasPage
                            object={object}
                            setObject={setObject}
                            objectId={objectId}
                            saveEdits={saveEdits}
                          />}
                      />}
                    />}
                  />
                  <Route path="/events/:database_id" element={<EventPage />} />
                  <Route path="/events" element={<EventsPage />} />
                  <Route path="/" element={<Navigate to="/dashboard" />} />
                </Routes>
              </main>
              <footer className="bg-gray-800 text-white text-center py-4">
                &copy; {new Date().getFullYear()} Personal Canvas
              </footer>
              <ScrollToArrows />
            </Router>
          </ObjectsProvider>
        </NotificationProvider>
      </ErrorProvider>
    </UserContextProvider>
  );
}

export default App;
