import React, { useState } from 'react';
import { useError } from '../contexts/ErrorContext';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function FileSystemViewer({ objects }) {
    const [expandedFolders, setExpandedFolders] = useState(new Set(['/apps', '/journals']));
    const [showNewFileModal, setShowNewFileModal] = useState(false);
    const [newFilePath, setNewFilePath] = useState('');
    const { showError } = useError();
    const navigate = useNavigate();

    const files = objects.filter(obj => obj.type === 'File');

    const isFile = (path) => {
        return path.includes('.') || /\d{4}-\d{2}-\d{2}$/.test(path);
    };

    const buildFileTree = () => {
        const tree = {};
        files.forEach(file => {
            if (!file.path) return;
            const parts = file.path.split('/').filter(Boolean);
            const fileName = parts.pop(); // Always treat the last part as a file
            let current = tree;
            
            // Build the directory structure
            for (const part of parts) {
                current[part] = current[part] || {};
                current = current[part];
            }
            
            // Add the file at the current level
            current[fileName] = file;
        });
        return tree;
    };

    const renderTree = (node, path = '') => {
        if (!node) return null;
        
        if (node.type === 'File') {
            return (
                <div className="ml-4 text-sm">
                    <Link 
                        to={`/files/${node.id}`}
                        className="text-blue-500 hover:underline"
                    >
                        {node.name || node.path.split('/').pop()}
                    </Link>
                </div>
            );
        }

        return Object.entries(node).map(([name, child]) => {
            const fullPath = `${path}/${name}`;
            const isExpanded = expandedFolders.has(fullPath);

            if (child.type === 'File') {
                return (
                    <div key={fullPath} className="ml-4 text-sm">
                        <Link 
                            to={`/files/${child.id}`}
                            className="text-blue-500 hover:underline"
                        >
                            {child.name || name}
                        </Link>
                    </div>
                );
            }

            return (
                <div key={fullPath} className="ml-4">
                    <div 
                        className="flex items-center cursor-pointer"
                        onClick={() => {
                            const newExpanded = new Set(expandedFolders);
                            if (isExpanded) {
                                newExpanded.delete(fullPath);
                            } else {
                                newExpanded.add(fullPath);
                            }
                            setExpandedFolders(newExpanded);
                        }}
                    >
                        <span className="mr-2">{isExpanded ? '▼' : '▶'}</span>
                        <span className="font-bold">{name}/</span>
                    </div>
                    {isExpanded && renderTree(child, fullPath)}
                </div>
            );
        });
    };

    const createNewFile = async () => {
        try {
            const response = await axios.post('/api/files', {
                path: newFilePath,
                name: newFilePath.split('/').pop(),
                content: '',
                labels: ['app-file']
            }, { withCredentials: true });

            const newFile = response.data.file;
            setShowNewFileModal(false);
            setNewFilePath('');
            navigate(`/files/${newFile.id}`);
        } catch (error) {
            console.error('Error creating file:', error);
            showError('Error creating file:', error);
        }
    };

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">File System</h2>
                <button
                    onClick={() => setShowNewFileModal(true)}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                    New File
                </button>
            </div>

            <div className="border rounded p-4">
                {renderTree(buildFileTree())}
            </div>

            {showNewFileModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-4 rounded">
                        <h3 className="text-lg font-bold mb-4">Create New File</h3>
                        <input
                            type="text"
                            value={newFilePath}
                            onChange={(e) => setNewFilePath(e.target.value)}
                            placeholder="/apps/MyApp/main.js"
                            className="w-full p-2 border rounded mb-4"
                        />
                        <div className="flex justify-end gap-2">
                            <button
                                onClick={() => setShowNewFileModal(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={createNewFile}
                                className="bg-blue-500 text-white px-4 py-2 rounded"
                            >
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default FileSystemViewer; 