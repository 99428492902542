import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Modal from 'react-modal';
import ObjectLoader from '../ObjectLoader';
import ConversationPage from '../../pages/ConversationPage';
import './AutoDiaryModule.css';
import { v4 as uuidv4 } from 'uuid';
import { getCalendarColors } from '../Calendar';
import { useObjects } from '../../contexts/ObjectsContext';

function AutoDiaryModule() {
    const [moduleData, setModuleData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [calendars, setCalendars] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [eventSources, setEventSources] = useState([]);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const { objects, getObjects } = useObjects();

    // Initial load of module data from context
    useEffect(() => {
        const foundModuleData = objects.find(obj => obj.type === 'ModuleData' && obj.name === 'AutoDiaryModule');
        if (foundModuleData) {
            setModuleData(foundModuleData);
        }
    }, [objects]);

    // Separate initialization effect
    useEffect(() => {
        fetchCalendars();
    }, []);

    // Active refresh function - only fetches module data
    async function refreshModuleData() {
        setLoading(true);
        try {
            const response = await axios.get(`/api/objects/${moduleData.id}`, { withCredentials: true });
            setModuleData(response.data.object);
        } catch (error) {
            console.error('Error refreshing Auto Diary module data:', error);
        } finally {
            setLoading(false);
        }
    }

    async function fetchCalendars() {
        try {
            const res = await axios.get('/api/calendars/lists', { withCredentials: true });
            const sources = res.data.map(calendar => ({
                id: calendar.id,
                events: (info, successCallback, failureCallback) =>
                    fetchEventsForCalendar(calendar.id, info, successCallback, failureCallback),
                ...getCalendarColors(calendar.backgroundColor)
            }));
            setEventSources(sources);
            setCalendars(res.data);
        } catch (error) {
            console.error('Error fetching calendar lists:', error);
        }
    }

    const fetchEventsForCalendar = async (calendarId, info, successCallback, failureCallback) => {
        try {
            const response = await axios.get('/api/calendars/events', {
                params: {
                    timeMin: info.start.toISOString(),
                    timeMax: info.end.toISOString(),
                    calendarId
                },
                withCredentials: true,
            });

            const events = response.data.map(event => ({
                id: event.id,
                title: event.summary,
                start: event.start.dateTime || event.start.date,
                end: event.end.dateTime || event.end.date,
                allDay: !event.start.dateTime,
                source: 'google' // Mark as Google Calendar source
            }));

            successCallback(events);
        } catch (error) {
            failureCallback(error);
        }
    };

    async function handleInstall() {
        try {
            setLoading(true);
            await axios.post('/api/modules/autoDiary/install', {}, { withCredentials: true });
            await refreshModuleData();
        } catch (error) {
            console.error('Error installing Auto Diary module:', error);
        } finally {
            setLoading(false);
        }
    }

    async function handleUninstall(keepData = false) {
        try {
            setLoading(true);
            await axios.post('/api/modules/autoDiary/uninstall', { keepData }, { withCredentials: true });
            setModuleData(null);
        } catch (error) {
            console.error('Error uninstalling Auto Diary module:', error);
        } finally {
            setLoading(false);
        }
    }

    async function saveModuleData() {
        try {
            await axios.put(`/api/objects/${moduleData.id}`, moduleData, { withCredentials: true });
        } catch (error) {
            console.error('Error saving module data:', error);
        }
    }

    if (loading) {
        return <div>Loading Auto Diary Module...</div>;
    }

    if (!moduleData) {
        return (
            <div>
                <h2>Auto Diary Module</h2>
                <p>This module helps you add your day's events to the calendar via AI.</p>
                <button onClick={handleInstall}>Install Auto Diary</button>
            </div>
        );
    }

    // Called when user clicks an event on the FullCalendar preview
    function handleEventClick(clickInfo) {
        const event = clickInfo.event;
        const clickedEvent = moduleData.data.events.find((e) => e.id === event.id);
        if (clickedEvent) {
            setSelectedEvent(clickedEvent);
            setShowModal(true);
        }
    }

    // When the user selects a calendar from the dropdown in the modal
    function handleCalendarChange(e) {
        const newCalendarId = e.target.value;
        setSelectedEvent((prev) => ({ ...prev, calendarId: newCalendarId }));
    }

    // Stores the updated chosen calendar ID back into moduleData's event array
    function applyCalendarSelection() {
        if (!selectedEvent) return;

        // If the event is new (not in the array yet)
        if (!moduleData.data.events.find(e => e.id === selectedEvent.id)) {
            setModuleData(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    events: [...prev.data.events, selectedEvent]
                }
            }));
        } else {
            // Update existing event
            setModuleData(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    events: prev.data.events.map(e =>
                        e.id === selectedEvent.id ? selectedEvent : e
                    )
                }
            }));
        }
        saveModuleData();
        setShowModal(false);
    }

    // Handle when user selects a time slot
    function handleDateSelect(selectInfo) {
        const newEvent = {
            id: uuidv4(),
            title: '',
            start: selectInfo.startStr,
            end: selectInfo.endStr,
            description: '',
            calendarId: 'primary' // Set default calendar to primary
        };

        setSelectedEvent(newEvent);
        setShowModal(true);
        selectInfo.view.calendar.unselect();

        // Focus on title input after modal opens
        setTimeout(() => {
            const titleInput = document.querySelector('input[type="text"]');
            if (titleInput) {
                titleInput.focus();
            }
        }, 100);
    }

    // Handle when user resizes an event
    function handleEventResize(resizeInfo) {
        const updatedEvent = {
            ...moduleData.data.events.find(e => e.id === resizeInfo.event.id),
            start: resizeInfo.event.startStr,
            end: resizeInfo.event.endStr
        };

        setModuleData(prev => ({
            ...prev,
            data: {
                ...prev.data,
                events: prev.data.events.map(e =>
                    e.id === updatedEvent.id ? updatedEvent : e
                )
            }
        }));
        saveModuleData();
    }

    // Handle when user drags an event
    function handleEventDrop(dropInfo) {
        const updatedEvent = {
            ...moduleData.data.events.find(e => e.id === dropInfo.event.id),
            start: dropInfo.event.startStr,
            end: dropInfo.event.endStr
        };

        setModuleData(prev => ({
            ...prev,
            data: {
                ...prev.data,
                events: prev.data.events.map(e =>
                    e.id === updatedEvent.id ? updatedEvent : e
                )
            }
        }));
        saveModuleData();
    }

    function handleDeleteEvent() {
        if (!selectedEvent) return;

        setModuleData(prev => ({
            ...prev,
            data: {
                ...prev.data,
                events: prev.data.events.filter(e => e.id !== selectedEvent.id)
            }
        }));
        saveModuleData();
        setShowModal(false);
        setShowDeleteConfirm(false);
    }

    return (
        <div className="auto-diary-module">
            <div style={{ display: 'flex' }}>
                <div style={{ flex: '1', marginRight: '20px', overflow: 'hidden' }}>
                    <h2>Auto Diary AI Chat</h2>
                    <p>Below is the AI conversation. You can mention what you did today, and the AI will build events for you!</p>
                    <div className="conversation-container">
                        <ObjectLoader
                            objectId={moduleData.data.conversationId}
                            component={(object, setObject, objectId, saveEdits, isSaving, loadObject) => (
                                <ConversationPage
                                    conversation={object}
                                    setConversation={setObject}
                                    conversationId={objectId}
                                    saveEdits={saveEdits}
                                    isSaving={isSaving}
                                    loadObject={loadObject}
                                />
                            )}
                        />
                    </div>
                </div>

                <div style={{ flex: '1' }} className="calendar-container">
                    <h2>Auto Diary Preview</h2>
                    <p>Events with blue borders are in preview.</p>
                    <style>
                        {`
                            .preview-event {
                                border: 2px solid #3b82f6 !important;
                                border-radius: 4px;
                            }
                        `}
                    </style>
                    <FullCalendar
                        plugins={[timeGridPlugin, interactionPlugin]}
                        initialView="timeGridDay"
                        eventSources={[
                            ...eventSources,
                            {
                                events: moduleData.data.events.map(event => {
                                    const calendar = calendars.find(cal => cal.id === event.calendarId);
                                    const colors = getCalendarColors(calendar?.backgroundColor);
                                    return {
                                        ...event,
                                        classNames: ['preview-event'],
                                        backgroundColor: colors.backgroundColor,
                                        borderColor: colors.borderColor,
                                        textColor: colors.textColor
                                    };
                                }) || [],
                            }
                        ]}
                        editable={true}
                        selectable={true}
                        eventClick={handleEventClick}
                        select={handleDateSelect}
                        eventResize={handleEventResize}
                        eventDrop={handleEventDrop}
                        eventTimeFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                        }}
                    />
                    <div className="refresh-button-container">
                        <button 
                            onClick={refreshModuleData}
                            className="refresh-button"
                            disabled={loading}
                        >
                            {loading ? 'Refreshing...' : 'Refresh Events'}
                        </button>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        paddingTop: '100px',
                        zIndex: 1000
                    },
                    content: {
                        position: 'relative',
                        top: 'auto',
                        left: 'auto',
                        right: 'auto',
                        bottom: 'auto',
                        width: '400px',
                        padding: '20px',
                        borderRadius: '8px',
                        backgroundColor: 'white',
                        boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
                    }
                }}
            >
                {selectedEvent && (
                    <div className="space-y-4">
                        <div className="flex justify-between items-center">
                            <h3 className="text-xl font-semibold">Edit Event</h3>
                            <button onClick={() => setShowModal(false)} className="text-gray-500 hover:text-gray-700">✕</button>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Title</label>
                            <input
                                type="text"
                                value={selectedEvent.title || ''}
                                onChange={e => setSelectedEvent(prev => ({ ...prev, title: e.target.value }))}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Description</label>
                            <textarea
                                value={selectedEvent.description || ''}
                                onChange={e => setSelectedEvent(prev => ({ ...prev, description: e.target.value }))}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Calendar</label>
                            <select
                                value={selectedEvent.calendarId || 'primary'}
                                onChange={handleCalendarChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                            >
                                <option value="primary">Primary Calendar</option>
                                {calendars.map((cal) => (
                                    cal.id !== 'primary' && (
                                        <option key={cal.id} value={cal.id}>
                                            {cal.summary}
                                        </option>
                                    )
                                ))}
                            </select>
                        </div>

                        <div className="flex justify-between space-x-2 pt-4">
                            {!showDeleteConfirm ? (
                                <>
                                    <button
                                        onClick={() => setShowDeleteConfirm(true)}
                                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                                    >
                                        Delete
                                    </button>
                                    <button
                                        onClick={applyCalendarSelection}
                                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                    >
                                        Save Changes
                                    </button>
                                </>
                            ) : (
                                <>
                                    <div className="text-sm text-gray-600">Are you sure you want to delete this event?</div>
                                    <div className="space-x-2">
                                        <button
                                            onClick={() => setShowDeleteConfirm(false)}
                                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={handleDeleteEvent}
                                            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                                        >
                                            Confirm Delete
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </Modal>

            <div className="uninstall-buttons">
                <button
                    className="uninstall-button"
                    onClick={() => handleUninstall(false)}
                >
                    Uninstall Completely
                </button>
                <button
                    className="uninstall-keep-data-button"
                    onClick={() => handleUninstall(true)}
                >
                    Uninstall But Keep Data
                </button>
            </div>
        </div>
    );
}

export default AutoDiaryModule; 