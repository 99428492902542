import React from 'react';
import { Link } from 'react-router-dom';

const CanvasItem = ({ canvas }) => {
  return (
    <div className="flex items-start gap-2">
      <span className="text-xl">🎨</span>
      <div>
        <Link to={`/canvases/${canvas.id}`} className="text-blue-500 hover:underline">
          Open Canvas
        </Link>
      </div>
    </div>
  );
};

export default CanvasItem; 