// client/src/components/Dashboard.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useError } from '../contexts/ErrorContext';
import FileSystemViewer from '../components/FileSystemViewer';
import Calendar from '../components/Calendar';
import { useObjects } from '../contexts/ObjectsContext';
import { usePageTitle } from '../hooks/usePageTitle';

function Dashboard() {
  const navigate = useNavigate();
  const { showError } = useError();
  const { objects, getObjects, isLoading } = useObjects();
  const [expandedSections, setExpandedSections] = useState({
    Apps: true,
    Conversations: false,
    Notes: false,
    Jobs: false
  });

  usePageTitle('Dashboard');

  // Sort objects by date in descending order
  const sortedObjects = [...objects].sort((a, b) => {
    const dateA = new Date(a.date || a.start?.dateTime || 0);
    const dateB = new Date(b.date || b.start?.dateTime || 0);
    return dateB - dateA;
  });

  useEffect(()=>{
    getObjects();
  }, []);

  const createNewConversation = async () => {
    try {
      navigate(`/conversations/new`);
    } catch (error) {
      console.error('Error creating conversation:', error);
      showError('Error creating conversation:', error);
    }
  };

  const createNewApp = async () => {
    try {
      navigate(`/apps/new`);
    } catch (error) {
      console.error('Error creating app:', error);
      showError('Error creating app:', error);
    }
  };

  const createNewNote = async () => {
    try {
      navigate(`/notes/new`);
    } catch (error) {
      console.error('Error creating note:', error);
      showError('Error creating note:', error);
    }
  };

  const createNewJob = async () => {
    try {
      navigate(`/jobs/new`);
    } catch (error) {
      console.error('Error creating job:', error);
      showError('Error creating job:', error);
    }
  };

  const createNewCanvas = async () => {
    try {
      navigate(`/canvases/new`);
    } catch (error) {
      console.error('Error creating canvas:', error);
      showError('Error creating canvas:', error);
    }
  };

  const canvases = sortedObjects.filter(obj => obj.type === 'Canvas');
  const apps = sortedObjects.filter(obj => obj.type === 'App');
  const conversations = sortedObjects.filter(obj => obj.type === 'Conversation');
  const notes = sortedObjects.filter(obj => obj.type === 'Note');
  const jobs = sortedObjects.filter(obj => obj.type === 'Job');

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const renderSection = (title, items, createFn, route, buttonColor, countField, singular) => (
    <div className="mb-8">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <button
            onClick={() => toggleSection(title)}
            className="mr-2 text-gray-500 focus:outline-none"
          >
            {expandedSections[title] ? '▼' : '▶'}
          </button>
          <h2 className="text-2xl font-bold">{title}</h2>
          <span className="ml-2 text-gray-500">({items.length})</span>
        </div>
        <button
          onClick={createFn}
          className={`${buttonColor} text-white px-4 py-2 rounded hover:opacity-90`}
        >
          New {singular || title.slice(0, -1)}
        </button>
      </div>
      {expandedSections[title] && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {items.map(item => (
            <div
              key={item.id}
              onClick={() => navigate(`/${route}/${item.id}`)}
              className="p-4 border rounded cursor-pointer hover:bg-gray-50"
            >
              <h3 className="font-bold">{item.name}</h3>
              <p className="text-sm text-gray-600">
                {countField ? `${countField}: ${item[countField]?.length || 0}` : ''}
                <br />
                <span className="text-xs">
                  {new Date(item.date || item.start?.dateTime).toLocaleDateString()}
                </span>
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-xl font-bold text-gray-600">Loading...</div>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          {renderSection('Apps', apps, createNewApp, 'apps', 'bg-purple-500', null, 'app')}
          {renderSection('Conversations', conversations, createNewConversation, 'conversations', 'bg-green-500', 'messages', 'conversation')}
          {renderSection('Notes', notes, createNewNote, 'notes', 'bg-blue-500', 'content', 'note')}
          {renderSection('Jobs', jobs, createNewJob, 'jobs', 'bg-orange-500', 'nodes', 'job')}
          {renderSection('Canvases', canvases, createNewCanvas, 'canvases', 'bg-yellow-500', null, 'canvas')}
        </div>
        <div>
          <FileSystemViewer objects={objects} />
        </div>
      </div>
      <Calendar />
    </div>
  );
}

export default Dashboard;
