import React, { useState, useEffect, useRef } from 'react';
import ObjectLoader from '../components/ObjectLoader';
import ObjectEditor from '../components/ObjectEditor';
import ConversationPage from './ConversationPage';
import axios from 'axios';
import { useError } from '../contexts/ErrorContext';
import { useNavigate } from 'react-router-dom';
import { useObjects } from '../contexts/ObjectsContext';
import { Link } from 'react-router-dom';
import { uploadFile } from '../utils/firebaseUtils';
import { useUser } from '../contexts/UserContext';
// Add Phaser CDN URL
const PHASER_URL = "https://cdn.jsdelivr.net/npm/phaser@3.60.0/dist/phaser.min.js";

// Move Script component outside
const Script = ({ src }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.type = 'module';
        document.body.appendChild(script);

        return () => {
            if (document.body.contains(script)) {
                document.body.removeChild(script);
            }
        };
    }, [src]);

    return null;
};

// Add this component for the error popup
const ErrorPopup = ({ error, onYes, onNo, screenshotDataURL }) => {
    const [includeScreenshot, setIncludeScreenshot] = useState(true);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-6xl w-full flex gap-6">
                {/* Left column - Error text and buttons */}
                <div className="flex-1">
                    <h3 className="text-xl font-bold mb-4">Script Error</h3>
                    <p className="mb-4">Do you want the AI Assistant to fix this error?</p>
                    <pre className="bg-gray-100 p-4 rounded mb-4 overflow-auto max-h-[400px] whitespace-pre-wrap">
                        {error.toString()}
                    </pre>

                    {screenshotDataURL && (
                        <div className="mb-4">
                            <div className="flex items-center mb-2">
                                <input
                                    type="checkbox"
                                    id="includeScreenshot"
                                    checked={includeScreenshot}
                                    onChange={(e) => setIncludeScreenshot(e.target.checked)}
                                    className="mr-2"
                                />
                                <label htmlFor="includeScreenshot">Include screenshot</label>
                            </div>
                        </div>
                    )}

                    <div className="flex justify-end space-x-4 mt-4">
                        <button
                            onClick={onNo}
                            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                        >
                            No
                        </button>
                        <button
                            onClick={() => onYes(includeScreenshot)}
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        >
                            Yes
                        </button>
                    </div>
                </div>

                {/* Right column - Screenshot */}
                {screenshotDataURL && (
                    <div className="flex-1 border-l pl-6">
                        <div className="sticky top-6">
                            <h4 className="text-lg font-semibold mb-4">Error Screenshot</h4>
                            <img
                                src={screenshotDataURL}
                                alt="Error Screenshot"
                                className="w-full h-auto rounded border"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

const AppPage = ({ app, setApp, appId, saveEdits, isSaving }) => {
    const navigate = useNavigate();
    const { showError } = useError();
    const { objects, getObjects, isLoading: isLoadingObjects } = useObjects();
    const [files, setFiles] = useState([]);
    const [isRunning, setIsRunning] = useState(false);
    const [isLoadingFiles, setIsLoadingFiles] = useState(true);
    const [isUpdatingAttachments, setIsUpdatingAttachments] = useState(false);
    const [loadedScripts, setLoadedScripts] = useState([]);
    const [isPhaserLoaded, setIsPhaserLoaded] = useState(false);
    const appContainerRef = useRef(null);
    const [currentError, setCurrentError] = useState(null);
    const AIChatRef = useRef(null);
    const { user } = useUser();
    const [autoRun, setAutoRun] = useState(app?.autoRun ?? false);

    useEffect(() => {
        getObjects();
    }, []);

    useEffect(() => {
        if (appId === 'new') {
            axios
                .post('/api/apps', {}, { withCredentials: true })
                .then((response) => {
                    const newApp = response.data.object;
                    navigate(`/apps/${newApp.id}`, { replace: true });
                })
                .catch((error) => {
                    console.error('Error creating new app:', error);
                    showError('Error creating new app:', error);
                });
        }
    }, [appId, navigate]);

    // Add Phaser loading effect
    useEffect(() => {
        const script = document.createElement('script');
        script.src = PHASER_URL;
        script.async = true;
        script.onload = () => setIsPhaserLoaded(true);

        document.body.appendChild(script);

        return () => {
            if (document.body.contains(script)) {
                document.body.removeChild(script);
            }
        };
    }, []);

    // Initialize app context when Phaser is loaded
    useEffect(() => {
        if (isPhaserLoaded && appContainerRef.current) {
            window.appContext = {
                saveState: async (state) => {
                    try {
                        const updatedApp = { ...app, state };
                        setApp(updatedApp);
                        await saveEdits();
                    } catch (error) {
                        console.error('Error saving state:', error);
                        showError('Error saving state:', error);
                    }
                },
                loadState: async () => app.state || {},
                handleError: (error) => {
                    console.error('Script error:', error);
                    setCurrentError(error);
                },
                files: () => files
            };

            // Set up global error handler
            window.onerror = (msg, url, lineNo, columnNo, error) => {
                window.appContext.handleError(`${msg} at ${url} on line ${lineNo} column ${columnNo}: ${error}`);
                return false;
            };

            return () => {
                delete window.appContext;
                window.onerror = null;
            };
        }
    }, [isPhaserLoaded, app, saveEdits]);

    // Update files and conversation attachments when objects change
    useEffect(() => {
        if (!app) return;

        setIsLoadingFiles(true);

        try {
            if (isLoadingObjects) {
                return;
            }

            // Filter files from objects
            let appFiles = objects.filter(obj =>
                obj.type === 'File' &&
                obj.path.startsWith(`/apps/${app.name}/`)
            );
            // Sort files by name
            appFiles.sort((a, b) => a.name.localeCompare(b.name));
            setFiles(appFiles);

            // Get the conversation and update its attachments if needed
            if (app.conversationId) {
                setIsUpdatingAttachments(true);
                const conversation = objects.find(obj => obj.id === app.conversationId);

                if (conversation) {
                    // Check if attachments need updating
                    const currentAttachments = conversation.attachments || [];
                    const needsUpdate = appFiles.length !== currentAttachments.length ||
                        appFiles.some(file => !currentAttachments.find(att => att.id === file.id));

                    if (needsUpdate && JSON.stringify(appFiles) != JSON.stringify(conversation.attachments)) {
                        conversation.attachments = appFiles;
                        axios.put(`/api/objects/${app.conversationId}`, conversation, { withCredentials: true })
                            .catch(error => {
                                console.error('Error updating conversation attachments:', error);
                                showError('Error updating conversation attachments:', error);
                            });
                    }
                }
                setIsUpdatingAttachments(false);
            }
        } catch (error) {
            console.error('Error processing files:', error);
            showError('Error processing files:', error);
        } finally {
            setIsLoadingFiles(false);
        }
    }, [app, objects, isLoadingObjects]);

    const runApp = async () => {
        if (!isPhaserLoaded) {
            showError('Phaser is not loaded yet');
            return;
        }

        if (isLoadingFiles) {
            showError('Please wait for files to load');
            return;
        }

        try {
            setLoadedScripts([]);
            const jsFiles = files.filter(file => file.path.endsWith('.js'));

            if (jsFiles.length === 0) {
                showError('No JavaScript files found to run');
                return;
            }

            setIsRunning(true);

            // Load user scripts after Phaser is ready
            setLoadedScripts(jsFiles.map(file => ({
                id: file.id,
                src: `/api/files${file.path}`
            })));
        } catch (error) {
            console.error('Error running app:', error);
            showError('Error running app:', error);
            setIsRunning(false);
        }
    };

    const stopApp = () => {
        setLoadedScripts([]);
        setIsRunning(false);
        const appContainer = document.getElementById('app-container');
        if (appContainer) {
            appContainer.innerHTML = '';
        }
    };

    const handleErrorFix = async (includeScreenshot) => {
        if (!currentError || !app.conversationId || !AIChatRef.current) return;

        let messageContent = [
            {
                type: "text",
                text: `I encountered this error while running the app:
\`\`\`
${currentError.toString()}
\`\`\`
Please help me fix it. Update code in file directly.`
            }
        ];

        if (includeScreenshot) {
            try {
                const canvas = document.querySelector('#app-container canvas');
                if (canvas) {
                    const dataURL = canvas.toDataURL('image/png');
                    const imageURL = await uploadFile(user, dataURLtoFile(dataURL, 'error-screenshot.png'));
                    messageContent.push({
                        type: "image_url",
                        image_url: {
                            url: imageURL
                        }
                    });
                }
            } catch (error) {
                console.error('Error uploading screenshot:', error);
                showError('Error uploading screenshot:', error);
            }
        }

        stopApp();
        setCurrentError(null);
        await AIChatRef.current.handleSendMessage(messageContent);

        // Scroll to bottom after sending message
        setTimeout(() => {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
            });
        }, 100);
    };

    // Update the auto-run effect
    useEffect(() => {
        if (autoRun && !isLoadingFiles && !isRunning && files.length > 0 && isPhaserLoaded) {
            runApp();
        }
    }, [isLoadingFiles, files, autoRun, isPhaserLoaded]);

    // Update the handleAutoRunChange function
    const handleAutoRunChange = async (e) => {
        const newAutoRun = e.target.checked;
        setAutoRun(newAutoRun);
        const updatedApp = { ...app, autoRun: newAutoRun };
        setApp(updatedApp);
        await saveEdits();
    };

    if (!app) {
        return <div>Loading...</div>;
    }

    return (
        <div className="app-page">
            {/* App Header */}
            <div className="bg-white shadow px-6 py-4">
                <input
                    type="text"
                    value={app.name}
                    onChange={(e) => setApp({ ...app, name: e.target.value })}
                    onBlur={() => saveEdits()}
                    className="text-2xl font-bold w-full"
                    placeholder="App Name"
                />
            </div>

            {/* Main Content */}
            <div className="grid grid-cols-12 gap-4 p-6">
                {/* Files Panel */}
                <div className="col-span-2 bg-white rounded-lg shadow">
                    <div className="p-4">
                        <h2 className="text-xl font-bold mb-4">Files</h2>
                        {isLoadingFiles ? (
                            <div className="text-gray-500">Loading files...</div>
                        ) : (
                            <div className="space-y-2">
                                {files.map(file => (
                                    <div>
                                        <div className="text-sm text-gray-600">
                                            <Link to={`/files/${file.id}`} className="hover:underline">
                                                {file.path}
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {/* App Preview Panel */}
                <div className="col-span-10 bg-white rounded-lg shadow">
                    <div className="p-4">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-bold">Phaser Canvas</h2>
                            <div className="flex items-center space-x-4">
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="autoRun"
                                        checked={autoRun}
                                        onChange={handleAutoRunChange}
                                        className="mr-2"
                                    />
                                    <label htmlFor="autoRun">Run when page starts</label>
                                </div>
                                <button
                                    onClick={isRunning ? stopApp : runApp}
                                    className={`${isRunning ? 'bg-red-500' : 'bg-green-500'} text-white px-6 py-2 rounded-lg`}
                                    disabled={isLoadingFiles}
                                >
                                    {isRunning ? 'Stop App' : 'Run App'}
                                </button>
                            </div>
                        </div>
                        <div ref={appContainerRef} className="border rounded-lg p-4">
                            {!isRunning && (
                                <div className="text-gray-500 text-center py-8">
                                    Click "Run App" to start the Phaser application
                                </div>
                            )}
                            <div id="app-container" className="w-full h-[600px]"></div>
                            {loadedScripts.map(script => (
                                <Script key={script.id} src={script.src} />
                            ))}
                        </div>
                    </div>
                </div>

            </div>
            {/* AI Assistant Panel */}
            <div className="col-span-4 bg-white rounded-lg shadow">
                <div className="p-4">
                    <h2 className="text-xl font-bold mb-4">AI Assistant</h2>
                    {isUpdatingAttachments && (
                        <div className="text-gray-500 p-4 text-center">
                            Updating conversation attachments...
                        </div>
                    )}
                    {app.conversationId && !isUpdatingAttachments && (
                        <ObjectLoader
                            objectId={app.conversationId}
                            component={(object, setObject, objectId, saveEdits, isSaving, loadObject) => (
                                <ObjectEditor
                                    object={object}
                                    setObject={setObject}
                                    component={() =>
                                        <ConversationPage
                                            conversation={object}
                                            setConversation={setObject}
                                            conversationId={objectId}
                                            saveEdits={saveEdits}
                                            isSaving={isSaving}
                                            AIChatRef={AIChatRef}
                                            loadObject={loadObject}
                                        />
                                    }
                                />
                            )}
                        />
                    )}
                </div>
            </div>

            {/* Error Popup */}
            {currentError && (
                <ErrorPopup
                    error={currentError}
                    onYes={handleErrorFix}
                    onNo={() => setCurrentError(null)}
                    screenshotDataURL={document.querySelector('#app-container canvas')?.toDataURL('image/png')}
                />
            )}
        </div>
    );
};

export default AppPage;
