import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getLocalDate } from '../utils/webUtils';
import { useError } from '../contexts/ErrorContext';

const JobPage = ({ job, setJob, jobId, saveEdits, loadObject }) => {
  const navigate = useNavigate();
  const [agentNames, setAgentNames] = useState([]);
  const { showError } = useError();
  const [isRunningJob, setIsRunningJob] = useState(false);
  const pollIntervalRef = useRef(null);

  useEffect(() => {
    // Fetch agent names
    axios
      .get('/api/jobs/agentNames', { withCredentials: true })
      .then((response) => {
        setAgentNames(response.data.agentNames);
      })
      .catch((error) => {
        console.error('Error fetching agent names:', error);
      });
  }, []);

  useEffect(() => {
    if (jobId === 'new') {
      axios
        .post('/api/jobs',
          { date: getLocalDate() },
          { withCredentials: true }
        )
        .then((response) => {
          const newJob = response.data;
          navigate(`/jobs/${newJob.id}`, { replace: true });
        })
        .catch((error) => {
          console.error('Error creating new job:', error);
          showError('Error creating new job:', error);
        });
    }
  }, [jobId, navigate]);

  const handleChange = (field, value) => {
    setJob({
      ...job,
      [field]: value,
    });
  };

  const handleNodeChange = (index, field, value) => {
    const nodes = [...job.nodes];
    nodes[index][field] = value;
    handleChange('nodes', nodes);
  };

  const handleAddNode = () => {
    handleChange('nodes', [
      ...job.nodes,
      {
        message: null,
        agentName: null,
      },
    ]);
  };

  const saveJob = async () => {
    await saveEdits();
    alert('Job saved successfully!');
  };

  const toggleJobEnabled = async () => {
    handleChange('enabled', job.enabled === undefined ? false : !job.enabled);
  };

  useEffect(() => {
    if (isRunningJob) {
      // Start polling every 2 seconds when job is running
      pollIntervalRef.current = setInterval(() => loadObject(), 2000);
    }
    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
        pollIntervalRef.current = null;
      }
    };
  }, [isRunningJob]);

  const runJob = async () => {
    setIsRunningJob(true);
    try {
      await axios.post(`/api/jobs/${jobId}/test-run`, {}, { withCredentials: true });
      loadObject();
    } catch (error) {
      console.error('Error test running job:', error);
      showError('Error test running job: ' + error);
    } finally {
      setIsRunningJob(false);
    }
  };

  if (!job || !job.nodes) {
    return <div>Loading...</div>;
  }

  return (
    <div className="job-page p-4">
      <h1>
        <input
          type="text"
          value={job.name}
          onChange={(e) => handleChange('name', e.target.value)}
          placeholder="Job Title"
          className="border p-1 w-full mb-4 text-2xl"
        />
      </h1>

      <div className="mb-4">
        <button
          onClick={toggleJobEnabled}
          className={`p-2 ${job.enabled ? 'bg-red-500' : 'bg-green-500'} text-white rounded mr-2`}
        >
          {job.enabled === undefined ? 'Disable Job' : (job.enabled ? 'Disable Job' : 'Enable Job')}
        </button>
        
        {job.enabled === false && (
          <span className="text-red-500 ml-2">
            ⚠️ This job is currently disabled
          </span>
        )}
      </div>

      <div className="mb-4">
        <label className="block mb-2 font-bold">Start Date:</label>
        <input
          type="datetime-local"
          value={job.startDate}
          onChange={(e) => handleChange('startDate', e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>

      <div className="mb-4">
        <label className="block mb-2 font-bold">Frequency (hours):</label>
        <input
          type="number"
          value={job.frequencyInHours}
          onChange={(e) => handleChange('frequencyInHours', e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>

      <div className="mb-4">
        <label className="block mb-2 font-bold">Repeats:</label>
        <input
          type="text"
          value={job.repeats}
          onChange={(e) => handleChange('repeats', e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>

      <div className="mb-4">
        <label className="block mb-2 font-bold">Next Run Date:</label>
        <input
          type="text"
          value={job.nextRunDate}
          onChange={(e) => handleChange('nextRunDate', e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>

      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2">Nodes</h2>
        <div className={`${isRunningJob ? 'border-2 border-orange-400 p-4 rounded' : ''}`}>
          {job.nodes.map((node, index) => (
            <div key={index} className="mb-4 relative">
              <button 
                onClick={() => {
                  const nodes = [...job.nodes];
                  nodes.splice(index, 1);
                  handleChange('nodes', nodes);
                }}
                className="absolute top-0 right-0 text-red-500 hover:text-red-700 font-bold text-xl"
              >
                ×
              </button>
              <h3 className="text-lg font-bold mb-2">Node {index + 1}</h3>
              <label className="block mb-2 font-bold">Message:</label>
              <textarea
                value={node.message ? node.message.content[0].text : ''}
                onChange={(e) =>
                  handleNodeChange(index, 'message', {
                    content: [{ type: 'text', text: e.target.value }],
                  })
                }
                className="w-full p-2 border rounded"
              />
              <label className="block mb-2 font-bold">Assistant (Conversation):</label>
              <select
                value={node.agentName || ''}
                onChange={(e) => handleNodeChange(index, 'agentName', e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="">Select Conversation</option>
                {agentNames.map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </select>
              <div className="mt-2">
                <label className="block mb-2 font-bold">Output:</label>
                <div className="w-full p-2 bg-gray-100 rounded min-h-[50px]">
                  {isRunningJob && !node.output ? 'Waiting for response...' : (node.output || '')}
                </div>
              </div>
            </div>
          ))}
          <button onClick={handleAddNode} className="mt-2 p-2 bg-blue-500 text-white rounded">+ Add Node</button>
        </div>
      </div>

      <button onClick={saveJob} className="mt-4 p-2 bg-green-500 text-white rounded">Save Job</button>

      <button
        onClick={runJob}
        disabled={isRunningJob}
        className={`mt-2 ml-2 text-white px-3 py-1 rounded text-sm transition-colors ${
          isRunningJob ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
        }`}
      >
        {isRunningJob ? (
          <span className="flex items-center">
            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Running...
          </span>
        ) : (
          'Run Job Now'
        )}
      </button>

    </div>
  );
};

export default JobPage;
