import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Excalidraw } from "@excalidraw/excalidraw";
import { useError } from '../contexts/ErrorContext';
import { getLocalDate } from '../utils/webUtils';

const CanvasPage = ({ object, setObject, objectId, saveEdits }) => {
  const navigate = useNavigate();
  const { showError } = useError();
  const [excalidrawAPI, setExcalidrawAPI] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  // Step 1: If user navigates to "/canvases/new", create a new canvas record on mount
  useEffect(() => {
    if (objectId === 'new') {
      axios
        .post('/api/canvases', { date: getLocalDate() }, { withCredentials: true })
        .then((response) => {
          navigate(`/canvases/${response.data.object.id}`, { replace: true });
        })
        .catch((error) => {
          console.error('Error creating new canvas:', error);
          showError('Error creating new canvas:', error);
        });
    }
  }, [objectId, navigate, showError]);

  // Step 2: Parse initial data for Excalidraw
  const getInitialData = useCallback(() => {
    if (!object?.content) {
      return { elements: [], appState: { collaborators: [] } };
    }
    try {
      const parsed = JSON.parse(object.content);
      return {
        elements: Array.isArray(parsed.elements) ? parsed.elements : [],
        appState: {
          ...(typeof parsed.appState === 'object' ? parsed.appState : {}),
          collaborators: [], // Force an array (Excalidraw often expects this)
        },
      };
    } catch (err) {
      console.error('Error parsing canvas content:', err);
      return { elements: [], appState: { collaborators: [] } };
    }
  }, [object?.content]);

  // Step 3: Save changes when user clicks "Save Canvas"
  const handleSave = async () => {
    try {
      if (!excalidrawAPI) return; // If Excalidraw hasn’t mounted yet

      // Pull the current drawing data from Excalidraw
      const elements = excalidrawAPI.getSceneElements();
      const appState = excalidrawAPI.getAppState();
      appState.collaborators = []; // Sanitize field

      // Convert content to JSON
      const newContent = JSON.stringify({ elements, appState });

      // Create updated object
      const updatedObject = {
        ...object,
        content: newContent,
      };

      // Update state
      setObject(updatedObject);

      // Persist to server (this calls your global save logic)
      await saveEdits();

      setUnsavedChanges(false);
      alert('Canvas saved successfully!');
    } catch (error) {
      console.error('Save failed:', error);
      showError('Save failed:', error);
    }
  };

  if (!object) {
    return <div>Loading...</div>;
  }

  return (
    <div className="canvas-page">
      {/* Title field */}
      <div className="mb-4">
        <input
          type="text"
          value={object.name}
          onChange={(e) => {
            setObject({ ...object, name: e.target.value });
            setUnsavedChanges(true);
          }}
          placeholder="Canvas Title"
          className="text-2xl font-bold w-full p-2 border rounded"
        />
      </div>

      {/* Excalidraw area */}
      <div className="border rounded-lg" style={{ height: 'calc(100vh - 300px)' }}>
        <Excalidraw
          excalidrawAPI={(api) => setExcalidrawAPI(api)}
          onChange={() => setUnsavedChanges(true)}
          initialData={getInitialData()}
        />
      </div>

      {/* Labels field */}
      <div className="mt-4">
        <label className="block mb-2 font-bold">Labels:</label>
        <input
          type="text"
          value={object.labels ? object.labels.join(', ') : ''}
          onChange={(e) => {
            setObject({
              ...object,
              labels: e.target.value.split(',').map((l) => l.trim()),
            });
            setUnsavedChanges(true);
          }}
          className="w-full p-2 border rounded"
          placeholder="canvas, drawing, etc..."
        />
      </div>

      {/* Save button */}
      <button
        onClick={handleSave}
        disabled={!unsavedChanges}
        className={`mt-4 px-4 py-2 rounded text-white ${
          unsavedChanges ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-400'
        }`}
      >
        Save Canvas
      </button>
    </div>
  );
};

export default CanvasPage; 